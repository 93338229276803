.m-size {
	&-large {
		font-size: percentage( 18 / 14 );
	}
	&-xlarge {
		font-size: percentage( 20 / 14 );
	}
	&-xxlarge {
		font-size: percentage( 24 / 14 );
	}
}