.m-bg {
	padding-top: 40px;
	padding-bottom: 40px;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	
	&--grad {
		background: url(#{$img}common/bg-grad.jpg) no-repeat center center;
		background-size: cover;
	}
}