.m-link {
	color: #1a4e8a;
	text-decoration: underline;
	
	&-sp {
		pointer-events: none;
		
		@media #{$sp} {
			pointer-events: auto;
		}
	}
}