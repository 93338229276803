.m-feature {
	display: flex;
	justify-content: space-between;
	max-width: 912px;
	margin-left: auto;
	margin-right: auto;
	
	@media #{$sp} {
		flex-direction: column;
		align-items: center;
	}
	&-item {
		width: percentage( 440 / 912 );
		border: 1px solid #3b87b2;
		background-color: #ffffff;
		
		@media #{$sp} {
			width: auto;
			
			&:not(:first-child) {
				margin-top: 3rem;
			}
		}
		&__thumb {
			> img {
				display: block;
				width: 100%;
			}
			figcaption {
				// padding: 20px 25px;
				margin: 12px 0 0 0;
				text-align: left;
				color: #050e20;
				// font-size: 1.5rem;
				font-size: 1.3rem;
				// line-height: #{(33 / 15)};
				line-height: 1.5;
			}
		}
	}
}