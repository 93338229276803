.m-table {
	&-wrap01 {
		width: 100%;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;
	}
	&01,
	&02,
	&03,
	&04,
	&05,
	&-form {
		width: 100%;
		font-size: 1.4rem;
		line-height: 1.5;
		
		tr {
			> th {
				white-space: nowrap;
			}
			> th,
			> td {
				// padding: 12px 22px;
				padding: 12px 18px;
				font-weight: $fw-normal;
				
				@media #{$sp} {
					padding: spvw(8) spvw(12);
				}
			}
			> th:nth-child(1),
			> td:nth-child(1) {
				position: sticky;
				left: 0;
			}
			&:has(> td:nth-child(1)[rowspan="2"]) + tr,
			&:has(> th:nth-child(1)[rowspan="2"]) + tr {
				> th:nth-child(1),
				> td:nth-child(1) {
					position: static;
				}
			}
			&:has(> td:nth-child(1)[rowspan="3"]) + tr
			&:has(> td:nth-child(1)[rowspan="3"]) + tr + tr,
			&:has(> th:nth-child(1)[rowspan="3"]) + tr,
			&:has(> th:nth-child(1)[rowspan="3"]) + tr + tr {
				> th:nth-child(1),
				> td:nth-child(1) {
					position: static;
				}
			}
			&:has(> td:nth-child(1)[rowspan="4"]) + tr
			&:has(> td:nth-child(1)[rowspan="4"]) + tr + tr,
			&:has(> td:nth-child(1)[rowspan="4"]) + tr + tr + tr,
			&:has(> th:nth-child(1)[rowspan="4"]) + tr,
			&:has(> th:nth-child(1)[rowspan="4"]) + tr + tr,
			&:has(> th:nth-child(1)[rowspan="4"]) + tr + tr + tr {
				> th:nth-child(1),
				> td:nth-child(1) {
					position: static;
				}
			}
			&:has(> td:nth-child(1)[rowspan="5"]) + tr
			&:has(> td:nth-child(1)[rowspan="5"]) + tr + tr,
			&:has(> td:nth-child(1)[rowspan="5"]) + tr + tr + tr,
			&:has(> td:nth-child(1)[rowspan="5"]) + tr + tr + tr + tr,
			&:has(> th:nth-child(1)[rowspan="5"]) + tr,
			&:has(> th:nth-child(1)[rowspan="5"]) + tr + tr,
			&:has(> th:nth-child(1)[rowspan="5"]) + tr + tr + tr,
			&:has(> th:nth-child(1)[rowspan="5"]) + tr + tr + tr + tr {
				> th:nth-child(1),
				> td:nth-child(1) {
					position: static;
				}
			}
			&:has(> td:nth-child(1)[rowspan="6"]) + tr
			&:has(> td:nth-child(1)[rowspan="6"]) + tr + tr,
			&:has(> td:nth-child(1)[rowspan="6"]) + tr + tr + tr,
			&:has(> td:nth-child(1)[rowspan="6"]) + tr + tr + tr + tr,
			&:has(> td:nth-child(1)[rowspan="6"]) + tr + tr + tr + tr + tr,
			&:has(> th:nth-child(1)[rowspan="6"]) + tr,
			&:has(> th:nth-child(1)[rowspan="6"]) + tr + tr,
			&:has(> th:nth-child(1)[rowspan="6"]) + tr + tr + tr,
			&:has(> th:nth-child(1)[rowspan="6"]) + tr + tr + tr + tr,
			&:has(> th:nth-child(1)[rowspan="6"]) + tr + tr + tr + tr + tr {
				> th:nth-child(1),
				> td:nth-child(1) {
					position: static;
				}
			}
		}
		
		.w10 { width: 10%; }
		.w15 { width: 15%; }
		.w20 { width: 20%; }
		.w25 { width: 25%; }
		.w30 { width: 30%; }
		.w35 { width: 35%; }
		.w40 { width: 40%; }
		.w45 { width: 45%; }
		.w50 { width: 50%; }
		.w55 { width: 55%; }
		.w60 { width: 60%; }
		.w65 { width: 65%; }
		.w70 { width: 70%; }
		.w75 { width: 75%; }
		.w80 { width: 80%; }
		.w85 { width: 85%; }
		.w90 { width: 90%; }
		.w95 { width: 95%; }
		.w100 { width: 100%; }
	}
	&01,
	&02,
	&05,
	&-form {
		border-top: 1px solid #dfdfdf;
		border-left: 1px solid #dfdfdf;
		
		tr {
			> th,
			> td {
				border-bottom: 1px solid #dfdfdf;
				border-right: 1px solid #dfdfdf;
			}
		}
		
		.color1 { background-color: #dde5ed; }
	}
	&01 {
		tr {
			> th,
			> td {
				text-align: left;
			}
			&:nth-child(2n+1) {
				> th,
				> td {
					background-color: #efefef;
				}
			}
		}
		
	}
	&02 {
		tr {
			> th,
			> td {
				text-align: left;
			}
			> th {
				background-color: #efefef;
			}
		}
	}
	&03 {
		border-bottom: 1px solid #b2b2b2;
		border-right: 1px solid #b2b2b2;
		
		tr {
			> th {
				white-space: nowrap;
				background-color: #94b4cb;
			}
			> th,
			> td {
				border-left: 1px solid #b2b2b2;
				border-top: 1px solid #b2b2b2;
			}
		}
		thead {
			tr {
				> th,
				> td {
					&:nth-child(n+2) {
						border-left-color: #fff;
					}
				}
			}
		}
		tbody {
			tr {
				&:nth-child(n+1) {
					> th,
					> td {
						&:nth-child(1) {
							border-top-color: #fff;
						}
					}
				}
			}
		}
		tbody {
			&:nth-child(1) {
				tr {
					&:nth-child(1) {
						> th,
						> td {
							&:nth-child(1) {
								border-top-color: #b2b2b2;
							}
						}
					}
				}
			}
		}
		
		.color1 { background-color: #dde5ed; }
	}
	&04 {
		border-top: 1px solid #989898;
		border-bottom: 1px solid #989898;
		font-size: 1.6rem;
		
		tr {
			&:not(:first-child) {
				> th,
				> td {
					border-top: 1px dotted #989898;
				}
			}
			> th {
				text-align: left;
			}
		}
	}
	&05 {
		font-size: 1.0rem;
		
		tr {
			> th,
			> td {
				padding: 6px;
				
				@media #{$sp} {
					padding: spvw(6);
				}
			}
			> th {
				background-color: #efefef;
			}
		}
	}
	&-form {
		@media #{$sp} {
			display: block;
		}
		thead,
		tbody {
			@media #{$sp} {
				display: block;
			}
		}
		tr {
			@media #{$sp} {
				display: block;
			}
			> th,
			> td {
				text-align: left;
				
				@media #{$sp} {
					display: block;
					width: 100% !important;
					box-sizing: border-box;
				}
			}
			> th {
				background-color: #efefef;
			}
		}
	}
	&-form02 {
		width: 100%;
		
		@media #{$sp} {
			display: block;
		}
		tbody {
			@media #{$sp} {
				display: block;
			}
			tr {
				@media #{$sp} {
					display: block;
				}
				th {
					width: 7em;
					padding: 1em 3em 1em 0;
					text-align-last: justify;
					vertical-align: top;
					
					@media #{$sp} {
						display: block;
						width: 100%;
						padding: 1em 0 0 0;
						text-align-last: left;
					}
				}
				td {
					padding: 0.75em 0;
					
					@media #{$sp} {
						display: block;
						padding: 0.75em 0 0 0;
					}
					ul {
						display: flex;
						flex-wrap: wrap;
						
						li {
							margin-right: 1em;
						}
					}
				}
			}
		}
	}
	&-form03 {
		width: 100%;
		border-top: 1px solid #989898;
		border-bottom: 1px solid #989898;
		font-size: 1.6rem;
		line-height: 1.5;
		
		@media #{$sp} {
			display: block;
		}
		
		tbody {
			@media #{$sp} {
				display: block;
			}
		}
		tr {
			@media #{$sp} {
				display: block;
			}
			> th,
			> td {
				border-top: 1px dotted #989898;
				padding: 12px 22px;
				text-align: left;
				font-weight: $fw-normal;
				
				@media #{$sp} {
					display: block;
					padding: spvw(8) spvw(12);
				}
			}
			> th {
				white-space: nowrap;
				vertical-align: middle;
			}
			> td {
				@media #{$sp} {
					border-top: 0;
				}
			}
		}
	}
}