.m-form {
	input[type="text"],
	input[type="email"],
	input[type="tel"],
	input[type="password"] {
		padding: 0.75em;
		border: 1px solid #989898;
		border-radius: 3px;
	}
	textarea {
		width: 100%;
		height: 12em;
		padding: 0.75em;
		border: 1px solid #989898;
		border-radius: 3px;
		resize: vertical;
		box-sizing: border-box;
	}
	input[type="checkbox"],
	input[type="radio"] {
		margin-right: 0.4em;
	}
	select {
		padding: 0.75em;
		border: 1px solid #989898;
		border-radius: 3px;
	}
	.mT5 {
		margin-top: 5px;
	}
	.mT30 {
		margin-top: 30px;
	}
	.errorMsg {
		margin-top: 30px;
		padding: 0;
		
		.errorHead {
			padding: calc( 0.5em - 3px ) 0.5em 0.5em;
			background-color: red;
			color: #ffffff;
		}
		ul {
			padding: 1em;
			
			li {
				&:before {
					content: '※';
				}
			}
		}
	}
	a {
		color: #1a4e8a;
	}
	&-input {
		&--s,
		&--m,
		&--l {
			box-sizing: border-box;
		}
		&--s {
			max-width: 100%;
			width: 8em;
		}
		&--m {
			max-width: 100%;
			width: 16em;
		}
		&--l {
			max-width: 100%;
			width: 24em;
		}
	}
	&-table {
		width: 100%;
		
		tbody {
			th,
			td {
				padding-top: 6px;
				padding-bottom: 6px;
			}
			th {
				text-align: right;
			}
			td {
				padding-left: 12px;
			}
		}
	}
	&__submit {
		display: flex;
		justify-content: center;
		
		button,
		input[type="submit"] {
			min-width: 10em;
		}
	}
	&-required {
		display: inline-block;
		padding: 0.25em 0.4em;
		margin-left: 0.5em;
		border-radius: 3px;
		background-color: #ff0000;
		color: #fff;
		font-size: 12px;
		line-height: 1;
	}
}