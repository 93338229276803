.m-imagebox {
	position: relative;
	display: inline-block;
	
	&.is-full {
		display: block;
	}
	&__in {
		position: relative;
		border-radius: 6px;
		overflow: hidden;
		
		> img {
			display: block;
		}
	}
}