@charset "utf-8";

//-----------------------------------------------
// responsive setting
//-----------------------------------------------
$sp-base: 375;
$pc-base: 1280;
$pc-inner: 1132;
$pc-center: 980;

$large: "only screen and (min-width: 1281px)";
// $tb: "only screen and (max-width: 1100px)";
$tb2: "only screen and (max-width: 1220px)";
$tb: "only screen and (max-width: 1200px)";
$sp: "only screen and (max-width: 768px)";
$pc: "only screen and (min-width: 769px)";
$tb-over: "only screen and (min-width: 1201px)";