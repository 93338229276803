.m-width {
	@for $i from 1 through 20 {
		&-#{($i * 5)}per {
			width: #{( 1% * $i * 5)};
			margin-left: auto;
			margin-right: auto;
			
			@media #{$sp} {
				width: 100%;
			}
		}
	}
	@for $i from 1 through 20 {
		&-#{($i * 50)}px {
			max-width: #{( 50px * $i )};
			margin-left: auto;
			margin-right: auto;
		}
	}
}