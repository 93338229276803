@charset "utf-8";

/*===============================================
 home
===============================================*/
.p-home {
	&-kv {
		padding-bottom: 64px;
		
		@media #{$tb} {
			padding-bottom: 0;
		}
		&__slider {
			position: relative;
			width: 100%;
		}
		&__container {
			position: relative;
			width: 100%;
			padding-top: percentage( 466 / 1400 );
			background: url(#{$img}home/loading.gif) no-repeat center center;
			
			@media #{$sp} {
				background-size: 32px 32px;
			}
		}
		&__item {
			position: absolute;
			left: 0;
			top: 0;
			display: block;
			width: 100%;
			height: 100%;
			background-color: #fff;
			overflow: hidden;
			opacity: 0; // default
			
			a {
				display: block;
			}
			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
		&__overlay {
			position: absolute;
			left: 0;
			top: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			
			&.is-with-bg {
				&:before {
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background-color: #000;
					opacity: 0.3;
					content: '';
				}
			}
			&__in {
				position: relative;
				display: flex;
				width: 100%;
			}
			&__left {
				width: calc( 100% * 635 / 1400 );
				padding-top: calc( 100vw * 32 / 1400 );
				text-align: center;
			}
			&__center {
				width: 100%;
				padding-top: calc( 100vw * 24 / 1400 );
				text-align: center;
			}
			&__catch {
				display: block;
				color: #fff;
				// font-size: calc( 100vw * 28 / 1400 );
				font-size: calc( 100vw * 36 / 1400 );
				font-weight: 400;
				line-height: 1;
				letter-spacing: 0.05em;
			}
			&__center &__catch {
				/*
				text-shadow:
					0 0 0.1em #3b87b2,
					0 0 0.2em #3b87b2,
					0 0 0.3em #3b87b2,
					0 0 0.4em #3b87b2,
					0 0 0.5em #3b87b2;
					*/
				text-shadow:
					0 0 0.1em #000000,
					0 0 0.2em #000000,
					0 0 0.3em #000000,
					0 0 0.4em #000000,
					0 0 0.5em #000000;
			}
			&__btn {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: calc( 100vw * 194 / 1400 );
				height: calc( 100vw * 54 / 1400 );
				margin: calc( 100vw * 48 / 1400 ) auto 0;
				border: 1px solid #fff;
				border-radius: calc( 100vw * 7 / 1400 );
				color: #fff;
				font-size: calc( 100vw * 15 / 1400 );
				
				&:after {
					position: absolute;
					right: calc( 100vw * 20 / 1400 );
					top: 50%;
					width: calc( 100vw * 9 / 1400 );
					height: calc( 100vw * 9 / 1400 );
					border-top: 2px solid #fff;
					border-right: 2px solid #fff;
					transform: translateY(-50%) rotate(45deg);
					content: '';
				}
			}
			&__center &__btn {
				// background-color: rgba(59, 135, 178, 0.6);
				background-color: rgba(0, 0, 0, 0.6);
			}
		}
		&__nav {
			position: absolute;
			left: 50%;
			bottom: -32px;
			z-index: 1;
			display: flex;
			transform: translateX(-50%);
			
			@media #{$sp} {
				left: auto;
				right: spvw(8);
				bottom: spvw(8);
				transform: none;
			}
			> li {
				width: 8px;
				height: 8px;
				margin: 0 5px;
				border-radius: 50%;
				background-color: #edf5f7;
				transition: background-color .2s linear;
				cursor: pointer;
				
				@media #{$sp} {
					width: spvw(8);
					height: spvw(8);
					margin: 0 spvw(5);
				}
				
				&.is-active {
					background-color: #3b87b2;
					cursor: default;
					pointer-events: none;
				}
			}
		}
	}
	&-news {
		&__in {
			padding-top: 36px;
			padding-bottom: 45px;
		}
		&__ttl {
			color: #333333;
			font-size: 1.8rem;
			font-weight: $fw-bold;
			line-height: 1;
		}
		&__list {
			margin-top: 16px;
		}
		&-item {
			display: flex;
			padding: 12px 0;
			
			@media #{$sp} {
				flex-wrap: wrap;
			}
			&__date {
				padding-right: 16px;
				font-size: 1.4rem;
				line-height: #{(20 / 14)};
				white-space: nowrap;
			}
			&__cat {
				display: flex;
				width: 60px;
				height: 20px;
				border-radius: 10px;
				background-color: #1a4e8a;
				align-items: center;
				justify-content: center;
				color: #ffffff;
				font-size: 1.1rem;
				line-height: 1;
				white-space: nowrap;
			}
			&__ttl {
				padding-left: 11px;
				font-size: 1.4rem;
				font-weight: $fw-normal;
				line-height: #{(20 / 14)};
				
				@media #{$sp} {
					width: 100%;
					padding-left: 0;
					padding-top: 5px;
				}
				> a {
					color: #1a4e8a;
				}
			}
		}
		&__links {
			margin-top: 20px;
			text-align: right;
			font-size: 1.4rem;
			line-height: #{(20 / 14)};
			
			> a {
				margin-left: 2em;
				color: #1a4e8a;
				
				&:first-child {
					margin-left: 0;
				}
				&:before {
					display: inline-block;
					width: 8px;
					height: 13px;
					margin-right: 5px;
					vertical-align: -1px;
					background: url(#{$img}common/link-arw.png) no-repeat center center;
					content: '';
				}
			}
		}
	}
	&-aboutus {
		background: url(#{$img}home/bg-grad.jpg) no-repeat center center;
		background-size: cover;
		
		&__in {
			padding-top: 56px;
			padding-bottom: 48px;
		}
	}
	&-business {
		background: url(#{$img}home/bg-grad.jpg) no-repeat center center;
		background-size: cover;
		
		&__in {
			padding-top: 56px;
			padding-bottom: 48px;
		}
	}
	&-sustainability {
		background: url(#{$img}home/sustainability-bg.jpg) no-repeat center center;
		background-size: cover;
		
		&__in {
			padding-top: 56px;
			padding-bottom: 48px;
		}
	}
	&-recruit {
		background: url(#{$img}home/bg-grad.jpg) no-repeat center center;
		background-size: cover;
		
		&__in {
			padding-top: 56px;
			padding-bottom: 64px;
		}
	}
	&-company {
		&__in {
			padding-top: 56px;
			padding-bottom: 64px;
		}
	}
}