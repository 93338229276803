@charset "utf-8";

/*===============================================
 header
===============================================*/
.l-header {
	position: fixed;
	left: 0;
	top: 0;
	z-index: $z-header;
	width: 100%;
	height: 149px;
	background-color: $color-white;
	transform: translateY(0%);
	transition: transform 1.2s $ease-out-circ;
	
	@media #{$sp} {
		height: spvw( 90 );
	}
	
	&.is-hidden {
		transform: translateY(-100%);
	}
	&__in {
		padding-top: 30px;
		@include clearfix;
		
		@media #{$sp} {
			padding-top: spvw(20);
		}
	}
	&__logo {
		float: left;
		width: 201px;
		
		@media #{$tb} {
			width: 172px;
		}
		@media #{$sp} {
			width: spvw( 120 );
		}
		> a {
			display: block;
			
			> img {
				display: block;
			}
		}
	}
	&__corpo {
		float: right;
		// width: 348px;
		margin-top: 10px;
		
		@media #{$sp} {
			// width: spvw( 348 * 0.4 );
			margin-top: 0;
		}
		img {
			display: block;
			width: auto;
			height: 36px;
			
			@media #{$sp} {
				height: spvw( 36 * 0.4 );
			}
			html[lang="en"] & {
				height: 34px;
				
				@media #{$tb} {
					height: 26px;
				}
				@media #{$sp} {
					height: spvw( 11 );
				}
			}
		}
	}
	&__admin {
		clear: right;
		float: right;
		margin-top: 8px;
		color: #0062a0;
		font-size: 22px;
		font-weight: bold;
	}
	&__nav {
		clear: right;
		float: right;
		display: flex;
		margin-top: 8px;
		
		@media #{$tb} {
			clear: none;
			width: calc( 100% - 216px );
			justify-content: flex-end;
			margin-top: 28px;
		}
		@media #{$sp} {
			width: calc( 100% - #{spvw(120)} );
			margin-top: spvw( 10 );
		}
		
		> ul {
			display: flex;
			
			@media #{$tb} {
				pointer-events: none;
				opacity: 0;
				flex-direction: column;
				position: absolute;
				left: 0;
				top: 150px;
				width: 100%;
				max-height: calc( 100vh - 150px );
				background-color: #fff;
				box-shadow: 0 6px 6px rgba(0,0,0,0.5);
				overflow-y: auto;
				-webkit-overflow-scrolling: touch;
			}
			@media #{$sp} {
				top: spvw( 90 );
				max-height: calc( 100vh - #{spvw( 90 )} );
			}
			> li {
				// padding-right: 10px;
				padding-right: 6px;
				
				@media #{$tb} {
					padding-right: 0;
				}
				> a {
					position: relative;
					display: block;
					padding: 20px 12px 28px 18px;
					color: #000000;
					font-size: 17px;
					font-weight: $fw-medium;
					line-height: 1;
					white-space: nowrap;
					
					&:before {
						@media #{$tb-over} {
							position: absolute;
							left: 50%;
							top: 48px;
							width: 36px;
							height: 3px;
							background-color: #0082ba;
							opacity: 0;
							transform: translateX(-50%) scaleX(0);
							transition: opacity .2s, transform .2s ease-out;
							content: '';
						}
					}
					&.is-current:before {
						@media #{$tb-over} {
							opacity: 1;
							transform: translateX(-50%) scaleX(1);
						}
					}
				}
				&:hover > a {
					&:before {
						@media #{$tb-over} {
							opacity: 1;
							transform: translateX(-50%) scaleX(1);
						}
					}
				}
			}
		}
	}
	&__nav.is-active {
		> ul {
			@media #{$tb} {
				opacity: 1;
				pointer-events: auto;
			}
		}
	}
	&__spmenu {
		display: none;
		
		@media #{$tb} {
			position: relative;
			display: block;
			width: 24px;
			height: 24px;
			margin-right: 8px;
		}
		.border {
			position: absolute;
			left: 0;
			top: 50%;
			width: 100%;
			height: 3px;
			background-color: #1a4e8a;
			transition: opacity .2s, transform .2s ease-out;
			
			&:nth-child(1) {
				transform: translateY(-2px);
			}
			&:nth-child(2) {
				transform: translateY(-10px);
			}
			&:nth-child(3) {
				transform: translateY(6px);
			}
		}
	}
	&__nav.is-active &__spmenu {
		.border {
			&:nth-child(1) {
				opacity: 0;
			}
			&:nth-child(2) {
				transform: translateY(-2px) rotate(45deg);
			}
			&:nth-child(3) {
				transform: translateY(-2px) rotate(-45deg);
			}
		}
	}
	&__parent {
		> a {
			border-radius: 8px 8px 0 0;
			
			@media #{$tb} {
				border-radius: 0;
			}
			&:after {
				display: inline-block;
				width: 10px;
				height: 7px;
				margin: 0 0 0 6px;
				background: url(#{$img}common/gnav-arw.png) no-repeat;
				content: '';
			}
		}
		&:hover > a {
			@media #{$pc} {
				background-color: #dde5ed;
				color: #1a4e8a;
				opacity: 1 !important;
			}
		}
		&.is-hover > a {
			background-color: #dde5ed;
			color: #1a4e8a;
			opacity: 1 !important;
			
			&:after {
				transform: rotate(180deg);
			}
		}
	}
	&__child {
		display: none;
		position: absolute;
		left: 0;
		z-index: 99;
		top: 100%;
		width: 100%;
		
		@media #{$tb} {
			position: relative;
			top: 0;
		}
	}
	/*
	&__parent:hover &__child {
		@media #{$pc} {
			display: block;
		}
	}
	*/
	&__parent.is-hover &__child {
		display: block;
	}
	&__search {
		padding: 18px 0 0 0;
		
		@media #{$tb} {
			padding: 0;
		}
		> a {
			display: block;
			width: 24px;
			height: 24px;
			padding: 0;
			background: url(#{$img}common/gnav-search.png) no-repeat center center;
		}
	}
	&-searchbox {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		background-color: #dde5ed;
		display: none; // default
		
		.gsc-input-box {
			padding-top: 0;
			padding-bottom: 0;
		}
		
		&__in {
			max-width: 440px;
			margin-left: auto;
			
			@media #{$sp} {
				max-width: none;
				margin-left: 0;
			}
		}
	}
	&__language {
		padding: 16px 0 0 0;
		margin: 0 8px 0 0;
		
		@media #{$tb} {
			padding: 0;
			margin: 0 4px 0 0;
		}
		
		&__select {
			position: relative;
			
			select {
				display: block;
				width: 50px;
				border: 0;
				outline: 0;
				padding: 5px 12px 5px 5px;
				appearance: none;
				text-align: center;
				color: #0189b6;
				font-size: 1.4rem;
			}
			&:after {
				content: "";
				margin-top: -2px;
				border-width: 4px 4px 0;
				border-style: solid;
				border-color: #0189b6 transparent;
				position: absolute;
				top: 50%;
				right: 4px;
				pointer-events: none;
				z-index: 2;
			}
		}
	}
	&-drop {
		width: 100%;
		background-color: #dde5ed;
		
		&__in {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 40px 0;
			
			@media #{$tb} {
				padding: 0 20px 25px !important;
			}
			@media #{$sp} {
				padding: 0 12px 16px !important;
			}
		}
		&__heading {
			// width: 160px;
			width: percentage( 160 / $pc-center );
			flex: 0 0 none;
			
			@media #{$tb} {
				display: none;
			}
		}
		&__ttl {
			&-ja {
				display: block;
				font-size: 24px;
				line-height: 1;
			}
			&-en {
				display: block;
				margin-top: 16px;
				color: #1a4e8a;
				font-size: 16px;
				line-height: 1;
			}
		}
		&__body {
			display: flex;
			justify-content: space-between;
			flex: 1 1 auto;
			padding: 0 0 0 40px;
			border-left: 1px solid #ffffff;
			
			@media #{$tb} {
				flex-direction: column;
				padding: 0;
				border-left: 0;
			}
		}
		&__sect {
			padding-top: 12px;
			padding-bottom: 12px;
			border-top: 1px solid #fff;
			
			&:first-child {
				padding-top: 0;
				border-top: 0;
			}
			&:last-child {
				padding-bottom: 0;
			}
		}
		&__column {
			@media #{$sp} {
				margin-top: 1.6rem;
				
				&:first-child {
					margin-top: 0;
				}
			}
			p {
				line-height: 1;
				
				a {
					color: #1a4e8a;
				}
			}
			ul {
				display: flex;
				
				@media #{$sp} {
					flex-wrap: wrap;
				}
				> li {
					position: relative;
					max-width: 202px;
					
					@media #{$sp} {
						max-width: 49%;
					}
					&:not(:first-child) {
						margin-left: 24px;
						
						@media #{$sp} {
							margin-left: 0;
						}
					}
					@media #{$sp} {
						&:nth-child(n+3) {
							margin-top: 2%;
						}
						&:nth-child(2n) {
							margin-left: 2%;
						}
					}
					/*
					&:after {
						position: absolute;
						right: 0;
						bottom: 0;
						width: 35px;
						height: 22px;
						background: url(#{$img}common/gnav-menu-arw.svg) no-repeat right bottom;
						background-size: 100% 100%;
						pointer-events: none;
						content: '';
					}
					*/
					a {
						display: block;
						border: 1px solid #249188;
						border-radius: 12px;
						overflow: hidden;
						
						figure {
							position: relative;
							display: block;
							margin: 0;
							font-size: 12px;
							
							&:before {
								position: absolute;
								left: 0;
								right: 0;
								bottom: 0;
								// height: 30%;
								height: 3em;
								// background: linear-gradient(to top, rgba(0,93,149,1) 0%,rgba(0,93,149,0.8) 18%,rgba(0,93,149,0) 100%);
								background: rgba(0,93,149,0.6);
								transition: height .2s;
								content: '';
							}
							img {
								display: block;
							}
							figcaption {
								position: absolute;
								left: 0;
								bottom: 1em;
								width: 100%;
								box-sizing: border-box;
								text-align: center;
								color: #ffffff;
								font-weight: bold;
								line-height: 1;
								transition: bottom .2s;
							}
						}
						&:hover {
							opacity: 1 !important;
						}
						&:hover figure {
							&:before {
								height: 100%;
							}
							figcaption {
								bottom: calc( 50% - 0.5em );
							}
						}
					}
				}
			}
		}
	}
}
