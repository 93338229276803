@charset "utf-8";

/*===============================================
 footer
===============================================*/
.l-footer {
	&-nav {
		background-color: #dde5ed;
		
		&__in {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 38px 0;
			
			@media #{$sp} {
				flex-direction: column;
			}
		}
		&-group {
			width: percentage( 0.95 / 4 );
			
			&:nth-child(n+5) {
				margin-top: 2rem;
			}
			@media #{$sp} {
				width: 100%;
				
				&:nth-child(n+5) {
					margin-top: 0;
				}
			}
			&__ttl {
				color: #0089b6;
				font-size: 15px;
				font-weight: bold;
				line-height: 1;
				white-space: nowrap;
			}
			&__ttl + &__ttl {
				margin-top: 2rem;
			}
			&__in {
				display: flex;
				justify-content: space-between;
				
				@media #{$sp} {
					flex-direction: column;
				}
			}
		}
		&-sect {
			padding-left: 2rem;
			padding-top: 1rem;
			padding-bottom: 1rem;
			border-left: 1px solid #0089b6;
			
			@media #{$sp} {
				padding-left: 0;
				border-left: 0;
			}
			&:not(:first-child) {
				margin-top: 2rem;
			}
			ul {
				margin-top: 16px;
				font-size: 15px;
				line-height: 1;
				
				@media #{$sp} {
					display: flex;
					flex-wrap: wrap;
				}
				> li {
					margin-top: 12px;
					
					&:first-child {
						margin-top: 0;
					}
					@media #{$sp} {
						margin-top: 0;
						margin-right: 1em;
						margin-bottom: 1em;
						white-space: nowrap;
					}
					&:before {
						color: #0089b6;
						font-weight: bold;
						content: '- ';
					}
					> a {
						color: #0089b6;
					}
				}
			}
		}
	}
	&-inquiry {
		position: relative;
		min-height: 97px;
		
		@media #{$tb} {
			min-height: 130px;
		}
		&__in {
			display: flex;
			align-items: center;
			padding-top: 30px;
			padding-bottom: 30px;
			border-bottom: 1px solid #cccccc;
			
			@media #{$sp} {
				flex-direction: column;
				align-items: flex-start;
				padding-top: 15px;
				padding-bottom: 15px;
			}
		}
		&__ttl {
			width: 116px;
			font-size: 15px;
			font-weight: $fw-bold;
			
			@media #{$sp} {
				width: 100%;
			}
		}
		&__company {
			/*
			padding-left: 24px;
			padding-bottom: 2px;
			border-left: 1px solid #cccccc;
			*/
			
			@media #{$sp} {
				padding-left: 0;
				padding-top: 10px;
				border-left: 0;
			}
			
			&:before {
				display: block;
				margin-bottom: -5px;
				content: '';
			}
		}
		&__name {
			display: block;
			/*
			font-size: 17px;
			font-weight: $fw-bold;
			line-height: 1;
			*/
			
			/*
			@media #{$sp} {
				width: spvw( 222 * 0.4 );
			}
			*/
			img {
				display: block;
				width: auto;
				height: 23px;
			}
		}
		&__address {
			margin-top: 1em;
			font-size: 11px;
			font-weight: $fw-medium;
			font-style: normal;
			line-height: 1.5;
		}
		&__contact {
			display: flex;
			margin-left: auto;
			
			@media #{$tb} {
				flex-direction: column;
				margin-right: 10px;
			}
			@media #{$sp} {
				flex-direction: row;
				margin-right: 0;
				margin-left: 0;
				margin-top: 10px;
			}
		}
		&__tel,
		&__email {
			> a {
				display: block;
				padding-left: 35px;
				color: #1a4e8a;
				font-size: 16px;
				font-weight: $fw-medium;
				
				@media #{$sp} {
					padding-left: 24px;
					font-size: 14px;
				}
			}
		}
		&__tel {
			> a {
				background: url(#{$img}common/footer-tel.png) no-repeat left center;
				
				@media #{$sp} {
					background-size: 18px auto;
				}
			}
		}
		&__email {
			margin-left: 45px;
			
			@media #{$tb} {
				margin-left: auto;
				margin-top: 5px;
			}
			@media #{$sp} {
				margin-top: 0;
				margin-left: 10px;
			}
			> a {
				background: url(#{$img}common/footer-email.png) no-repeat left center;
				
				@media #{$sp} {
					background-size: 18px auto;
				}
			}
		}
	}
	&-fixed {
		position: relative;
		left: 0;
		bottom: 0;
		z-index: $z-footer;
		width: 100%;
		background-color: #f7f7f7;
	}
	&-inquiry.is-fixed &-fixed {
		position: fixed;
		transform: translateY(100%);
		transition: transform 1.2s $ease-out-circ;
	}
	&-inquiry.is-fixed.is-visible &-fixed {
		transform: translateY(0%);
	}
	&-agreement {
		width: 100%;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: $z-agreement;
		background-color: #d9d9d9;
		transform: translateY(100%);
		transition: transform 1.2s $ease-out-circ;
		
		&__container {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			// min-height: 97px;
			height: 97px;
			padding: 0 20px;
			box-sizing: border-box;
			
			@media #{$tb} {
				// min-height: 130px;
				height: 130px;
			}
			@media #{$sp} {
				// min-height: 143px;
				height: 156px;
				padding: 15px 20px;
				flex-direction: column;
				justify-content: center;
			}
		}
		&__content {
			width: calc( 100% - 188px );
			
			@media #{$sp} {
				width: 100%;
			}
			> p {
				font-size: 1.5rem;
				line-height: 1.8;
				
				@media #{$sp} {
					font-size: 1.2rem;
					line-height: 1.5;
				}
				a {
					color: #1a4e8a;
				}
			}
		}
		&__btn {
			width: 168px;
			
			@media #{$sp} {
				width: 100%;
				margin-top: 10px;
			}
		}
	}
	&-agreement.is-visible {
		transform: translateY(0%);
	}
	&-info {
		&__in {
			padding-top: 30px;
			padding-bottom: 35px;
		}
		&__links {
			display: block;
			text-align: center;
			font-size: 1.3rem;
			line-height: 1;
			
			> li {
				display: inline-block;
				padding: 0 12px;
				
				@media #{$sp} {
					display: block;
					padding: 0;
					margin-top: 1em;
					
					&:first-child {
						margin-top: 0;
					}
				}
				
				> a {
					color: #1a4e8a;
				}
			}
		}
		&__links + &__links {
			margin-top: 1em;
		}
		&__copyright {
			margin-top: 20px;
			text-align: center;
			font-size: 11px;
			line-height: 1;
			
			@media #{$sp} {
				font-size: 10px;
			}
		}
	}
	&-pagetop {
		position: fixed;
		right: #{(80 * 0.75)}px;
		bottom: #{(80 * 0.75)}px;
		z-index: $z-pagetop;
		
		@media #{$sp} {
			right: 10px;
			bottom: 10px;
		}
		
		&.is-with-agreement {
			bottom: 110px;
			
			@media #{$sp} {
				bottom: 165px;
			}
		}
		> a {
			display: block;
			width: #{(40 * 0.75)}px;
			height: #{(40 * 0.75)}px;
			background: url(#{$img}common/pagetop-arw.svg) no-repeat;
			background-size: 100% 100%;
		}
	}
}
